.wrapper {
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 80px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 400px;
  width: 100%;
}

.logo {
  max-width: 133px;
  width: 100%;
}
.socialContainer {
  display: flex;
  align-items: center;
}
.button {
  padding: 12px 16px;
  transition: 0.4s;
}
.button:hover {
  background: #022715;
  transform: translateY(0);
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 520px) {
  .socialContainer {
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .button {
    padding: 10px 12px;
  }
}
