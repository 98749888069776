.wrapper {
  padding: 80px 0;
  padding-top: 150px;
  background: url(../../../images/bg.png), var(--Base-950);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-height: 100vh;
  display: grid;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.headingContainer {
  width: 100%;
}
.highlight {
  color: var(--Green-500);
}
.heading,
.secondLine {
  line-height: 120.667%;
  letter-spacing: -2.76px;
}
.arrow {
  max-width: 94px;
  width: 100%;
  transform: translateY(12%);
}
.info {
  max-width: 666px;
  margin: 0 auto;
}
@media only screen and (max-width: 1199px) {
  .arrow {
    max-width: 64px;
  }
}
@media only screen and (max-width: 991px) {
  .secondLine {
    max-width: 500px;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-top: 120px;
  }
  .arrow {
    max-width: 52px;
  }
  .secondLine {
    max-width: 100%;
    margin-left: auto;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    gap: 32px;
  }
  .secondLine {
    max-width: 255px;
  }
  .heading,
  .secondLine {
    letter-spacing: -2px;
  }
  .arrow {
    max-width: 36px;
  }
}
