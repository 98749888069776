.wrapper {
  padding: 100px 0;
  padding-top: 200px;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: flex-start;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 60px 0;
    padding-top: 120px;
  }
  .container {
    grid-template-columns: 1fr;
    gap: 48px;
  }
}
