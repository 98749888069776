.wrapper {
  padding: 200px 0;
  background: var(--Base-900);
}
.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}
.blogs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px 12px;
  width: 100%;
}
.blog {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px;
  transition: 0.4s;
  border: 1px solid transparent;
  border-radius: 16px;
}
.blog:hover {
  border: 1px solid var(--Green-500);
  transform: translateY(1px);
}
.img {
  width: 100%;
  border-radius: 8px;
  display: block;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.learnMore {
  color: var(--Green-500);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.444%;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: auto;
}
.arrow {
  color: var(--Base-0);
  font-size: 20px;
}
.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}
.date {
  margin-left: auto;
}
@media only screen and (max-width: 1199px) {
  .blog {
    padding: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .blogs {
    grid-template-columns: 1fr 1fr;
  }
  .blog {
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    max-width: 600px;
  }
  .blogs {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 120px 0;
  }
}
