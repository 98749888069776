.wrappper {
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 24px;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
}
.input {
  color: var(--Base-0);
  font-size: 28px;
  font-style: normal;
  font-weight: 250;
  line-height: 157.143%;
  letter-spacing: -0.56px;
  border-bottom: 1px solid var(--Base-0);
  width: 100%;
  padding: 6px;
}
.input::placeholder {
  color: var(--Placeholder);
  opacity: 1;
}
.input::-moz-placeholder {
  color: var(--Placeholder);
  opacity: 1;
}
@media only screen and (max-width: 1199px) {
  .container {
    gap: 16px;
  }
  .inputContainer {
    gap: 24px;
  }
}
@media only screen and (max-width: 1024px) {
  .container {
    gap: 24px;
  }
  .inputContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    gap: 48px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .inputContainer {
    flex-direction: row;
  }

  .input {
    font-size: 24px;
  }
}

@media only screen and (max-width: 520px) {
  .inputContainer {
    flex-direction: column;
    align-items: center;
  }
  .input {
    font-size: 20px;
  }
}
