.button {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 20px;
  color: var(--Base-0);
  border-radius: 0px;
  cursor: pointer;
  white-space: nowrap;
  background: var(--Green-500);
}
.button:hover {
  background: var(--Base-800);
  opacity: 1;
  transform: translateY(0);
  transition: 0.4s;
}

.transparent {
  background: transparent;
  color: var(--Base-0);
  font-weight: 400;
}

.wFull {
  width: 100%;
}
.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-right: 5px; /* Add some space between the spinner and the text */
}
.loading {
  gap: 0px;
  opacity: 0.8;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 520px) {
  .button {
    font-size: 14px;
    padding: 10px 16px;
  }
}
