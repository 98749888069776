.wrapper {
  padding: 200px 0;
  padding-bottom: 100px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
}

.card {
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: url(../../../images/pattern.png), var(--Base-100);
  backdrop-filter: blur(12px);
}
.logo {
  max-width: 111.429px;
  width: 100%;
  margin-bottom: 16px;
}
.card:first-child .logo {
  max-width: 148.57px;
}
.titleAndPosition {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.position {
  color: var(--Green-500);
}
.info {
  display: grid;
  align-items: center;
}
@media only screen and (max-width: 1280px) {
  .card {
    padding: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 120px 0;
    padding-bottom: 60px;
  }
  .container {
    gap: 32px;
  }
  .cards {
    grid-template-columns: 1fr;
  }
  .logo {
    max-width: 111.429px;
  }
}
@media only screen and (max-width: 520px) {
  .card {
    padding: 24px;
  }
}
