.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  height: 80px;
  background: var(--Base-950);
}
.wrapperBg {
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 10px 0;
}
.logoContainer {
  display: flex;
}
.logo {
  max-width: 133px;
  width: 100%;
}
.navItems {
  display: flex;
  align-items: center;
}
.navItem {
  color: var(--Base-0);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  cursor: pointer;
  padding: 12px 16px;
}
.navItem {
  padding: 12px 16px;
  transition: 0.4s;
}
.navItem:hover {
  background: #022715;
  transform: translateY(0);
  opacity: 1;
}
.navActive {
  color: var(--Green-500);
  font-weight: 500;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.mobileButtonContainer {
  display: none;
}
.icon,
.hamburgerIcon {
  display: none;
}
.socialContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 32px;

  background: var(--Base-950);
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  min-width: max-content;
  display: none;
  padding: 24px 24px;
  padding-left: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}
.showSocialContainer {
  display: flex;
}
.socialMedia {
  display: flex;

  align-items: center;
  gap: 12px;
  transition: 0.4s;
}
.socialMedia:hover {
  transform: translateY(-1px);
  opacity: 0.8;
}
.socialIcon {
  color: var(--Base-0);
  font-size: 18px;
}
.wrapper .button {
  color: var(--Green-500);
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 0;
  border-radius: 6px;
  background: transparent;
  padding: 12px 16px;
}
.wrapper .button:hover {
  background: #022715;
  transform: translateY(0);
  opacity: 1;
}
@media only screen and (max-width: 1199px) {
  .navItem {
    padding: 12px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  .navItems {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    background: var(--Base-950);
    backdrop-filter: blur(32px);
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 0;
    right: 0;
    padding: 32px;
    padding-top: 80px;
    max-width: 320px;
    width: 100%;
    height: 100vh;
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.4);
    overflow-y: auto;
    z-index: 6;
  }
  .navItem {
    padding: 12px 16px;
    font-size: 20px;
  }
  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .separator {
    display: none;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .button {
    font-size: 14px;
    padding: 10px 15px;
    margin-top: 16px;
  }
  .desktopButton {
    display: none;
  }
  .icon {
    display: block;
    cursor: pointer;
    color: var(--Green-500);
    font-size: 28px;
    position: fixed;
    top: 20px;
    right: 20px;
  }
  .hamburgerIcon {
    display: block;
    cursor: pointer;
    max-width: 24px;
    width: 100%;
  }
  .socialContainer {
    display: flex;
    background: transparent;
  }

  .talkWithus {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-left: 16px;
  }
  .dekstopSocialContainer {
    display: none;
  }
  .showSocialContainer {
    display: none;
  }
  .socialIcon {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .logo {
    max-width: 86.63px;
  }
  .navItems {
    padding: 24px;
    padding-top: 80px;
  }
}
