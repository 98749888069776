.headingContainer {
  display: flex;

  justify-content: flex-start;
  align-items: flex-end;

  width: max-content;
  gap: 8px;
}
.reverse {
  flex-direction: row-reverse;
  margin-left: auto;
}
.type2Container {
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  gap: 16px;
}

.heading {
  font-size: 64px;
  color: var(--Base-800);

  font-style: normal;

  font-weight: 300;
  line-height: 110%;

  width: max-content;

  position: relative;
}
.base0 {
  color: var(--Base-0);
}
.reverse .heading {
  text-align: right;
}
.type2 {
  max-width: 100%;
  text-align: center;
}
.arrowContainer {
  width: 80px;

  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(5%);

  right: 0;
  bottom: 0;
  border: 5px solid var(--Green-500);
}
.icon {
  color: var(--Green-500);
  font-size: 40px;
}
.type2Container .arrowContainer {
  transform: translateY(5%);
}
.type2Container .icon {
  transform: rotate(90deg);
}
.reverse .icon {
  transform: rotate(180deg);
}
.arrowContainer:hover .icon {
  transition: transform 0.5s ease-in-out;
  transform: rotate(360deg); /* Rotates the icon */
}
.reverse .arrowContainer:hover .icon {
  transform: rotate(540deg);
}
.type2Container .arrowContainer:hover .icon {
  transform: rotate(450deg);
}
@media only screen and (max-width: 1199px) {
  .heading {
    font-size: 56px;
  }
  .arrowContainer {
    width: 60px;
    height: 60px;
    border-width: 4px;
  }
  .icon {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .reverse {
    flex-direction: row;
    margin: 0;
  }
  .reverse .heading {
    text-align: left;
  }
  .reverse .icon {
    transform: rotate(360deg);
  }

  .reverse .arrowContainer:hover .icon {
    transform: rotate(720deg);
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 48px;
  }
  .arrowContainer {
    width: 52px;
    height: 52px;
    border-width: 3px;
  }
  .icon {
    font-size: 26px;
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 40px;
  }

  .arrowContainer {
    width: 44px;
    height: 44px;
  }
  .icon {
    font-size: 22px;
  }
}
