.wrapper {
  background: var(--Base-900);
  padding: 200px 0;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.headingContainer {
  order: 2;
}
.facility {
  border: 2px solid var(--Base-400);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: var(--Base-950);
  backdrop-filter: blur(12px);
}

.info {
  display: grid;
  align-items: center;
}
.icon {
  max-width: 80px;
  margin-left: auto;
  margin-top: auto;
}
@media only screen and (max-width: 1280px) {
  .facility {
    padding: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 120px 0;
  }
  .container {
    grid-template-columns: 1fr;
  }
  .headingContainer {
    order: -1;
    margin-bottom: 24px;
  }
  .icon {
    max-width: 64px;
  }
}
@media only screen and (max-width: 520px) {
  .facility {
    padding: 24px;
  }
}
