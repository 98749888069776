.wrapper {
  padding: 100px 0;
  padding-bottom: 200px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.services {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
}
.service {
  display: flex;
  flex-direction: column;

  gap: 24px;
  padding: 40px;
  border-radius: 16px;

  width: calc(33.33% - 24px);
  background: url(../../../images/pattern.png), var(--Base-100);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.service:hover {
  background: #e2f8e2;
  transition: 0.4s;
}

.myService.lastItem,
.myService.secondLastItem {
  max-width: 560px;
  width: calc(45% - 24px);
}
.oddServices.lastItem,
.myService2.lastItem {
  max-width: 700px;
  width: calc(50% - 24px);
}
.even .secondLast {
  max-width: 100%;
  width: calc(33.33% - 24px);
}
.onlyFour {
  max-width: 560px;
  width: calc(50% - 24px);
}
.heading {
  max-width: 390px;

  width: 100%;
  text-align: left;
}
.info {
  height: 100%;
  display: grid;
  align-items: center;
}
.learnMore {
  color: var(--Green-500);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.444%;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: auto;
}
.arrow {
  color: var(--Green-500);
  font-size: 20px;
}
@media only screen and (max-width: 1280px) {
  .services {
    gap: 20px;
  }
  .service {
    padding: 32px;
  }
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    padding-top: 60px;
    padding-bottom: 120px;
  }
  .services {
    gap: 16px;
  }
  .service {
    padding: 24px;
  }
}
@media only screen and (max-width: 1024px) {
  .services {
    gap: 20px;
  }
  .wrapper .service {
    width: calc(50% - 20px);
  }
  .wrapper .even.lastItem {
    width: calc(100% - 20px);
  }
  .service {
    padding: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-bottom: 120px;
  }
  .wrapper .service {
    width: calc(100%);
  }
  .wrapper .lastItem,
  .wrapper .secondLastItem {
    width: calc(100%);
    max-width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .service {
    padding: 24px;
  }
}
