.subscribeAndFooter {
  padding: 120px 0;

  background: url(../../images/bg.png), var(--Base-950);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 200px;
}
@media only screen and (max-width: 991px) {
  .subscribeAndFooter {
    padding: 80px 0;
  }
}
