.wrapper {
  padding-top: 100px;
  padding-bottom: 200px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 48px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}
.ceoWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ceoInfo {
  max-width: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.img {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
  display: b;
}
.name {
  color: var(--Green-500);
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 60px;
    padding-bottom: 120px;
  }
  .container {
    grid-template-columns: 1fr;
  }
  .infoContainer {
    margin-top: 8px;
  }
  .ceoWrapper {
    justify-content: center;
  }
}
